<template>
  <div class="login_container">
    <div class="login_head">
      <img src="../assets/images/home/head.png" alt />
    </div>
    <div class="login_box">
      <!-- 文字区域 -->
      <div class="text_box">
        <p>欢迎登录</p>
        <p>Power by E-scope</p>
      </div>
      <!-- 表单区域 -->
      <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" class="login_form">
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" @keyup.enter.native="login" type="password" placeholder="请输入密码"
            show-password></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login" class="btn_login">登录</el-button>
          <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div class="login_foot">
      <!-- <img src="../assets/images/login/title_foot.png" alt /> -->
      <div class="base_info">
        <p>单位名称：唐山丰南纵横码头有限公司</p>
        <p>单位地址：唐山市丰南区黑沿子镇黑沿子大桥西侧</p>
        <a href="https://beian.miit.gov.cn">津ICP备2022007847号-1</a>
        <!-- <p>联系邮箱：xwwhu@126.com</p> -->
      </div>
    </div>
    <!-- <base-container :options="{width:1920,height:1080}" name='login-container' refName='loginContainer'>
    </base-container> -->
  </div>
</template>

<script>
import request from "../utils/request-common";
import baseContainer from '@/components/container/base-container.vue'

export default {
  name: "LoginIndex",
  data () {
    return {
      loginForm: {
        // username: "test",
        // password: "gh4536251",
        username: "",
        password: "",
      },
      loginFormRules: {
        username: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符之间",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: { 
    // baseContainer
  },
  created () {
    console.log(new Date().getHours());
  },
  methods: {
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields();
    },
    async login () {
      try {
        if (!this.tideLevel) {
          const { data } = await request("fn/login", {
            username: this.loginForm.username,
            password: this.loginForm.password,
          });
          if (data.data && data.data.token) {
            this.$store.commit("changeState", {
              success: true,
              token: data.data.token,
            });
            this.$message({
              message: "登录成功！",
              type: "success",
              center: true,
            });
            this.$router.push("/home");
          } else {
            this.$store.commit("changeState", {
              success: false,
            });
            this.$message({
              message: "账号或密码无效！",
              type: "error",
              center: true,
            });
          }
        }
      } catch (err) {
        this.$store.commit("changeState", {
          success: false,
        });
        console.log("网络错误，请求失败！", err);
      }

      // this.$refs.loginFormRef.validate(async (valid) => {
      //   if (!valid) return
      //   const { data: res } = await this.$http.post('login', this.loginForm)
      //   if (res.meta.status !== 200) return this.$message.error('登录失败！')
      //   this.$message.success('登录成功！')
      //   // 保存 token
      //   window.sessionStorage.setItem('token', res.data.token)
      //   this.$router.push('/home')
      // })
    },
  },
};
</script>
<style lang="scss" scoped>
.login_container {
  background-image: url("../assets/images/login/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  // min-width: 1300px;
}

::v-deep .el-input__inner {
  height: 50px;
  // padding: 0 20px;
}

// .login_head {
//   position: absolute;
//   top: 0;
//   left: 50%;
//   transform: translateX(-50%);
//   height: 88px;
//   width: 1460px;
//   background-image: url("../assets/images/home/head.png");
//   background-size: 100%;
//   background-repeat: no-repeat;
// }

.login_head {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 120px;
  width: 100%;
  min-width: 1080;
  background-image: linear-gradient(to bottom,
      rgb(3, 12, 30),
      rgb(3, 10, 24, 0.6) 70%,
      rgba(0, 0, 0, 0));
  text-align: center;
  pointer-events: none;

  img {
    width: 1460px;
    height: 88px;
  }

  // 适配移动端
  @media (max-width: 1600px) {
      img {
        width: 100%;
        height: auto;
    }
  }
}

.login_box {
  width: 360px;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);

  .text_box {
    text-align: center;
    margin-bottom: 50px;

    p {
      background-image: linear-gradient(#fff, #73abff);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;

      &:nth-child(1) {
        font-size: 34px;
        font-weight: 900;
        letter-spacing: 3px;
        margin-bottom: 5px;
      }

      &:nth-child(2) {
        font-size: 12px;
        font-weight: 700;
        opacity: 0.6;
      }
    }
  }

  .btns {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    .btn_login {
      width: 120px;
    }
  }
}

.login_foot {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;

  .base_info {
    display: flex;
    margin-bottom: 8px;

    // font-weight: 900;
    p {
      background-image: linear-gradient(#fff, #86b4fa);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-size: 14px;
      font-weight: bold;
      margin-left: 30px;
      opacity: 0.8;
      // text-shadow: 0 0 4px #82b1f7;
    }

    a {
      background-image: linear-gradient(#fff, #86b4fa);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-size: 14px;
      font-weight: bold;
      margin-left: 30px;
      opacity: 0.8;
    }
  }
}
</style>
